<template>
  <v-container class="ma-0 pa-0">
    <v-row align="center" justify="center" v-if="showViz">
      <v-col>
        <v-card
          outlined
          :style="
            'height: 60vh; min-width: 350px; background: #000 url(' +
            stars +
            ');'
          "
          class="ma-0 pa-0"
        >
          <v-card-text class="ma-0 pa-0" id="visualizer" ref="visualizer">
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col>
        <v-card outlined style="padding: 10px; height: 102px; min-width: 350px">
          <v-card-text class="ma-0 pa-0">
            <v-btn
              rounded
              x-small
              color="grey"
              outlined
              class="my-1 mx-2 pa-2"
              style="position: absolute; top: 0; left: 0"
              @click="toggleViz()"
            >
              <svg
                width="10px"
                height="9px"
                viewBox="0 0 10 8"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Audio"
                  transform="translate(0.000000, 0.500000)"
                  stroke="currentColor"
                  stroke-width="1"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                >
                  <line x1="4.5" y1="1.67582" x2="4.5" y2="6" id="Line-3">
                    <animate
                      attributeType="XML"
                      attributeName="y1"
                      values="1;2;3;2;5;3;1"
                      dur="1.4s"
                      repeatCount="indefinite"
                      v-if="playing && !paused"
                    ></animate>
                  </line>
                  <line x1="2.5" y1="1.14678" x2="2.5" y2="6" id="Line-2">
                    <animate
                      attributeType="XML"
                      attributeName="y1"
                      values="2;1;2;3;4;1;3;2"
                      dur="1s"
                      repeatCount="indefinite"
                      v-if="playing && !paused"
                    ></animate>
                  </line>
                  <line x1="0.5" y1="1.67582" x2="0.5" y2="6" id="Line-1">
                    <animate
                      attributeType="XML"
                      attributeName="y1"
                      values="3;0;2;1;2;1;4;3;2;1;3;5;4;5;3;2;1;2;3"
                      dur="5s"
                      repeatCount="indefinite"
                      v-if="playing && !paused"
                    ></animate>
                  </line>
                </g>
              </svg>
              Visualizer
            </v-btn>
            <v-btn
              icon
              small
              class="ma-0 pa-0"
              style="position: absolute; top: 0; right: 0"
              @click="close()"
              ><v-icon size="1rem">mdi-close</v-icon>
            </v-btn>
            <div class="mt-2">
              <v-row>
                <v-col class="text-center">
                  <v-row class="caption" style="line-height: 0.5rem">
                    <v-col class="text-center text-uppercase">
                      {{ artist }}</v-col
                    >
                  </v-row>
                  <v-row
                    no-gutters
                    class="caption text-uppercase text-truncate"
                  >
                    <v-col>
                      <div class="float-left">
                        {{ currentTime }}
                      </div>
                    </v-col>
                    <v-col>
                      <div>{{ song }}</div>
                    </v-col>
                    <v-col>
                      <div class="float-right">
                        {{ duration }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-progress-linear
                        v-model="percentage"
                        height="9"
                        style="
                          margin-top: 0px;
                          margin-bottom: 0px;
                          border-radius: 7px;
                        "
                        color="primary"
                        @click.native="setPosition()"
                        :disabled="!loaded"
                      ></v-progress-linear>
                      <audio
                        id="player"
                        ref="player"
                        crossorigin="anonymous"
                        v-on:ended="ended"
                        v-on:canplay="canPlay"
                        :src="link"
                      ></audio>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="mt-1">
                      <v-btn
                        icon
                        @click.native="playing ? pause() : play()"
                        :disabled="!loaded"
                      >
                        <v-icon v-if="!playing || paused">mdi-play</v-icon>
                        <v-icon v-else>mdi-pause</v-icon>
                      </v-btn>
                      <v-btn icon @click.native="stop()" :disabled="!loaded">
                        <v-icon>mdi-stop</v-icon>
                      </v-btn>
                      <v-btn icon v-if="!loaded" @click.native="reload()">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                      <v-btn icon @click.native="likeSong()">
                        <v-icon v-if="!likedSong">mdi-heart-outline</v-icon>
                        <v-icon v-if="likedSong">mdi-heart</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import starsBackground from "@/assets/stars.png";

const formatTime = (second) =>
  new Date(second * 1000)
    .toTimeString()
    .replace(/.*\d{2}:(\d{2}:\d{2}).*/, "$1");

export default {
  name: "MusicPlayer",
  props: {
    link: {
      type: String,
      default: null,
    },
    songNameLower: {
      type: String,
      default: null,
    },
    uid: {
      type: String,
      default: null,
    },
    artist: {
      type: String,
      default: null,
    },
    song: {
      type: String,
      default: null,
    },

    autoPlay: {
      type: Boolean,
      default: false,
    },
    ended: {
      type: Function,
      default: () => {},
    },
    canPlay: {
      type: Function,
      default: () => {},
    },
    likedSong: {
      type: Number,
      default: () => null,
    },
  },
  computed: {
    duration: function () {
      return this.audio ? formatTime(this.totalDuration) : "";
    },
  },
  data() {
    return {
      firstPlay: true,
      isMuted: false,
      loaded: true,
      playing: false,
      paused: false,
      percentage: 0,
      currentTime: "00:00",
      audio: undefined,
      totalDuration: 0,
      showViz: false,
      visualizer: null,
      stars: starsBackground,
    };
  },
  methods: {
    toggleViz() {
      if (this.showViz) {
        this.$refs["visualizer"] = null;
        this.showViz = false;
      } else {
        // ////////////////////////////////////////////////////
        // Set up scene
        let o;
        let l1;
        let l2;
        let l3;
        let circle;
        let circle2;

        let canWidth;
        let canHeight;
        this.showViz = true;
        var THREE = require("../assets/three.min.js");
        const scene = new THREE.Scene();
        const renderer = new THREE.WebGLRenderer({
          alpha: true,
          antialias: true,
        });
        let camera = new THREE.PerspectiveCamera();

        scene.add(camera);

        renderer.domElement.id = "vizcanvas";

        this.$nextTick(() => {
          if (this.$refs["visualizer"]) {
            canWidth = this.$refs["visualizer"].offsetWidth;
            canHeight = window.innerHeight * 0.6;
            renderer.setSize(canWidth, canHeight);
            this.$refs["visualizer"].appendChild(renderer.domElement); // ////////////////////////////////////////////////////
            camera = new THREE.PerspectiveCamera(
              40,
              canWidth / canHeight,
              0.05,
              300
            );
            camera.position.set(0, 25, 50);
          }
        });

        // ////////////////////////////////////////////////////
        // audio stuff
        const audio = document.getElementById("player");
        const vert = [];
        const context = new AudioContext();
        const src = context.createMediaElementSource(audio);
        const analyser = context.createAnalyser();
        src.connect(analyser);
        analyser.connect(context.destination);

        const bufferLength = analyser.frequencyBinCount;

        const frequencyData = new Uint8Array(bufferLength);
        function makePlane() {
          const g = new THREE.PlaneGeometry(900, 200, 30, 30);
          const m = new THREE.MeshStandardMaterial({
            flatShading: 1,
            wireframe: 0,
            color: 0x383838,
            metalness: 0.5,
            roughness: 0.6,
          });
          o = new THREE.Mesh(g, m);
          o.rotation.x = (Math.PI * 270) / 180;
          o.position.y = 0;
          scene.add(o);

          // Distort plane
          for (let x = 0; x < o.geometry.vertices.length; x += 1) {
            const v = o.geometry.vertices[x];
            const distanceFromCenterY = Math.abs(v.x) / 20;
            v.z +=
              distanceFromCenterY > 0.15
                ? (Math.random() * (20 - 0.15) + 0.15) * distanceFromCenterY * 2
                : Math.random() * (0.8 - 0.2) + 0.2 + distanceFromCenterY;
            vert[x] = v;
          }
        }

        function makeCircle() {
          const g = new THREE.CircleGeometry(18, 32);
          const loader = new THREE.TextureLoader();

          let m = new THREE.MeshBasicMaterial({
            color: 0xfdb813,
            map: loader.load(require("../assets/8k_sun.jpg")),
            wireframe: false,
          });

          let m2 = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            wireframe: false,
            opacity: 0.15,
          });
          m2.transparent = true;

          circle = new THREE.Mesh(g, m);
          circle.position.z = -100;
          scene.add(circle);

          circle2 = new THREE.Mesh(g, m2);
          circle2.position.z = -100;
          scene.add(circle2);
        }

        function makeLight() {
          l1 = new THREE.SpotLight(0xff6600, 5, 150, 20, 0, 2);
          l2 = new THREE.PointLight(0xff6600, 12);
          l3 = new THREE.PointLight(0xff6600, 0.6, 250);

          l1.position.set(0, 50, -130);
          l2.position.set(0, 0, -70);
          l3.position.set(0, 50, -150);

          scene.add(l1);
          scene.add(l2);
          scene.add(l3);
        }

        function makeScene() {
          makePlane();
          makeCircle();
          makeLight();
        }

        makeScene();

        function visualize() {
          analyser.getByteFrequencyData(frequencyData);
          let avg = frequencyData[30] / 200;
          let sunAvg;
          avg *= avg;

          if (avg > 0) {
            sunAvg = avg * 0.3 + 2;
            circle.scale.set(sunAvg, sunAvg, sunAvg);
            circle2.scale.set(sunAvg, sunAvg, sunAvg);
          } else {
            circle.scale.set(2, 2, 2);
            circle2.scale.set(2, 2, 2);
          }
          l1.intensity = avg * avg * 20;

          for (let x = 0; x < o.geometry.vertices.length; x += 1) {
            const v = o.geometry.vertices[x];

            v.z =
              Math.abs(
                /* keep waves above 0 */
                Math.sin(
                  (v.z + 1) /* keep waves alive when paused */ /
                    80 /* 40 - height of wave */
                ) *
                  ((frequencyData[Math.floor(x)] / 5) /* sample spread */ *
                    (vert[x].x / 13))
              ) / 2;
          }
          o.geometry.computeFaceNormals();
          o.geometry.verticesNeedUpdate = true;
        }

        // ////////////////////////////////////////////////////
        // Render it
        let cameraPan = 0;
        function render() {
          cameraPan += 0.001;
          camera.translateZ(Math.sin(cameraPan) / 20); // distance / multiplier
          visualize();

          requestAnimationFrame(render);
          renderer.render(scene, camera);
        }
        render();
      }
    },
    likeSong() {
      let song = {
        songURL: this.link,
        uid: this.uid,
        songNameLower: this.songNameLower,
      };
      this.$store.dispatch("likeSong", { song });
    },
    close() {
      this.$emit("close");
    },
    setPosition() {
      this.audio.currentTime = parseInt(
        (this.audio.duration / 100) * this.percentage,
        10
      );
    },
    stop() {
      this.paused = this.playing = false;
      this.audio.pause();
      this.audio.currentTime = 0;
      this.init();
    },
    play() {
      if (this.playing) return;
      this.paused = false;
      this.audio.play().then(() => (this.playing = true));
    },
    pause() {
      this.paused = !this.paused;
      this.paused ? this.audio.pause() : this.audio.play();
    },
    download() {
      this.audio.pause();
      window.open(this.file, "download");
    },
    mute() {
      this.isMuted = !this.isMuted;
      this.audio.muted = this.isMuted;
      this.volumeValue = this.isMuted ? 0 : 75;
    },
    reload() {
      this.audio.load();
    },
    _handleLoaded: function () {
      if (this.audio.readyState >= 2) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          this.audio.currentTime = 1e101;
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {};
            this.audio.currentTime = 0;
            this.totalDuration = parseInt(this.audio.duration, 10);
            this.loaded = true;
          };
        } else {
          this.totalDuration = parseInt(this.audio.duration, 10);
          this.loaded = true;
        }
        if (this.autoPlay) this.audio.play();
      } else {
        throw new Error("Failed to load sound file");
      }
    },
    _handlePlayingUI: function () {
      this.percentage = (this.audio.currentTime / this.audio.duration) * 100;
      this.currentTime = formatTime(this.audio.currentTime);
    },
    _handlePlayPause: function (e) {
      if (e.type === "play" && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0;
        if (this.firstPlay) {
          this.firstPlay = false;
        }
      }
      if (
        e.type === "pause" &&
        this.paused === false &&
        this.playing === false
      ) {
        this.currentTime = "00:00";
      }
    },
    _handleEnded() {
      this.paused = this.playing = false;
    },
    init: function () {
      this.audio.addEventListener("timeupdate", this._handlePlayingUI);
      this.audio.addEventListener("loadeddata", this._handleLoaded);
      this.audio.addEventListener("pause", this._handlePlayPause);
      this.audio.addEventListener("play", this._handlePlayPause);
      this.audio.addEventListener("ended", this._handleEnded);
    },
  },
  mounted() {
    this.audio = this.$refs.player;
    this.init();
    this.play();
  },
  beforeDestroy() {
    this.audio.removeEventListener("timeupdate", this._handlePlayingUI);
    this.audio.removeEventListener("loadeddata", this._handleLoaded);
    this.audio.removeEventListener("pause", this._handlePlayPause);
    this.audio.removeEventListener("play", this._handlePlayPause);
    this.audio.removeEventListener("ended", this._handleEnded);
  },
  watch: {
    song: function () {
      this.stop();
      this.percentage = 0;
      this.$nextTick(() => {
        this.play();
      });
    },
  },
};
</script>