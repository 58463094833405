var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{class:_vm.appBG},[_c('v-container',{staticClass:"pa-0"},[_c('br',{staticClass:"d-none d-md-flex"}),_c('v-card',{staticClass:"pa-3 lifted",attrs:{"outlined":"","tile":""}},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"bannerImage",class:{ darkFont: this.$vuetify.theme.dark }},[_c('div',[_vm._v("SPENCER")]),_c('div',[_vm._v("BURRIDGE")])])]),_c('v-container',[_c('v-tabs',{attrs:{"grow":"","value":_vm.tabIndex}},[_c('v-tab',{staticClass:"pa-0 ma-0",staticStyle:{"min-width":"0px","max-width":"0px","display":"none"}}),_vm._l((_vm.routeCalculation),function(route){return _c('v-tab',{key:route.path,attrs:{"to":route.path}},[_vm._v(_vm._s(route.name)+" ")])}),_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",staticStyle:{"min-width":"46px","width":"46px","height":"46px"},attrs:{"icon":""}},on),[(!_vm.loggedIn || !_vm.userProfile.profileImage)?_c('v-icon',[_vm._v("mdi-account")]):_vm._e(),(_vm.loggedIn && _vm.userProfile.profileImage)?_c('v-avatar',{attrs:{"size":"46"}},[_c('v-img',{attrs:{"src":_vm.userProfile.profileImage}})],1):_vm._e()],1)]}}])},[_c('v-list',{staticStyle:{"width":"270px"}},[(!_vm.loggedIn)?_c('div',[_c('LoginForm'),_c('div',{staticClass:"pt-4"},[_vm._v("Don't have an account?")]),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","href":"/createaccount"}},[_vm._v("Create an Account")])],1):_vm._e(),(_vm.loggedIn)?_c('v-btn',{attrs:{"text":"","block":"","to":"/settings"}},[_vm._v("Account")]):_vm._e(),(_vm.loggedIn)?_c('v-btn',{attrs:{"text":"","block":""},on:{"click":_vm.logout}},[_vm._v("Log out")]):_vm._e()],1)],1)],2)],1),_c('router-view'),_c('div',{staticClass:"py-10 d-flex d-md-none"}),_c('div',{staticClass:"py-3 d-flex d-md-none"})],1),_c('div',{staticClass:"py-10 d-none d-md-flex"}),_c('div',{staticClass:"py-8 d-none d-md-flex"}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: _vm.onIntersect,
          options: {
            threshold: 1.0,
          },
        }),expression:"{\n          handler: onIntersect,\n          options: {\n            threshold: 1.0,\n          },\n        }"}],staticClass:"d-none d-md-flex"}),(_vm.musicPlayerData.link)?_c('MusicPlayer',{staticClass:"ma-0 py-0",class:{ musicPlayerFooter: _vm.isIntersecting },attrs:{"link":_vm.musicPlayerData.link,"artist":_vm.musicPlayerData.artist,"song":_vm.musicPlayerData.song,"likedSong":_vm.musicPlayerData.likedSong,"songNameLower":_vm.musicPlayerData.songNameLower,"uid":_vm.musicPlayerData.uid,"id":"musicPlayer"},on:{"close":_vm.closePlayer}}):_vm._e()],1)],1),_c('v-footer',{staticClass:"hiddenFooter d-none d-md-flex",class:{ footer: _vm.isIntersecting },attrs:{"padless":""}},[_c('v-card',{staticClass:"text-center",staticStyle:{"width":"100vw"},attrs:{"tile":"","outlined":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticStyle:{"cursor":"pointer","user-select":"none"},attrs:{"icon":""},on:{"click":function($event){return _vm.changeTheme()}}},[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1),_c('v-btn',{staticStyle:{"cursor":"pointer","user-select":"none"},attrs:{"icon":"","to":!_vm.loggedIn ? '/login' : '/settings'}},[_c('v-icon',[_vm._v("mdi-account")])],1)],1),_c('v-col',{staticClass:"overline"},_vm._l((_vm.routeCalculation),function(route,index){return _c('span',{key:index},[_c('a',{attrs:{"href":route.path}},[_vm._v(_vm._s(route.name))]),(index + 1 < _vm.routeCalculation.length)?_c('span',[_vm._v(" | ")]):_vm._e()])}),0)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("Spencer Burridge")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }