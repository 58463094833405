var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"querysongs"},[_c('v-row',_vm._l((_vm.songsList),function(song){return _c('v-col',{key:song.songURL,staticClass:"col-12 col-sm-6 col-md-4"},[_c('v-card',{staticClass:"mx-auto text-left",staticStyle:{"overflow":"hidden"},attrs:{"outlined":"","max-width":"400"},on:{"click":function($event){return _vm.updateMusicPlayer(
            song.songURL,
            song.artistName,
            song.songName,
            song.likedSong,
            song.songNameLower,
            song.uid
          )}}},[(_vm.userProfile.uid)?_c('v-rating',{staticClass:"text-right float-right",attrs:{"empty-icon":"mdi-heart-outline","full-icon":"mdi-heart","length":"1","size":"16","color":"red lighten-3","clearable":""},on:{"input":function($event){return _vm.likeSong($event, song)}},model:{value:(song.likedSong),callback:function ($$v) {_vm.$set(song, "likedSong", $$v)},expression:"song.likedSong"}}):_vm._e(),_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-list-item',{staticClass:"mb-2",attrs:{"two-line":""}},[_c('v-list-item-avatar',{staticClass:"headline white--text",staticStyle:{"background-size":"cover","text-shadow":"0px 0px 7px #000"},style:({
                backgroundImage:
                  'url(' +
                  require('@/assets/blurrybackgroundsquare.jpg') +
                  ')',
              })},[_vm._v(_vm._s(_vm.songTitleFirstLetter(song.songName)))]),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(song.artistName))]),_c('v-list-item-title',{staticClass:"title",attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(song.songName)+" ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"ma-0 pa-1",staticStyle:{"overflow":"none"},style:('background-image: linear-gradient(36deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5)), url(' +
                _vm.imageLink +
                ')')},[_c('div',{staticClass:"caption text-right white--text mb-3 mx-4"},_vm._l((song.genre),function(genre){return _c('span',{key:genre.id},[_vm._v(" "+_vm._s(genre)+" ")])}),0)])],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }